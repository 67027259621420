/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
// import { toAbsoluteUrl, KTSVG } from '../../../../_metronic/helpers';
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';

import { Filter } from './Filter';

type Props = {
  onHandlerGuardHouse: (guardHouse_id: number) => void;
  companies: any[];
  guardHouse: any[];
};

const MonitoringMap: React.FC<Props> = ({
  onHandlerGuardHouse,
  companies,
  guardHouse,
}) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapHeight, setMapHeight] = useState(window.innerHeight - 350);
  // const [activeMarker, setActiveMarker] = useState<number | null>(null);

  const [companyId, setCompanyId] = useState(0);
  const [mapCenter, setMapCenter] = useState({
    lat: 25.6484,
    lng: -100.3329708,
  });
  // const [markers, setMarkers] = useState<MarkerModel[]>([]);

  // const polygonPoints = [
  //   { lat: 25.68116, lng: -100.347577 }, // Punto 1
  //   { lat: 25.681338, lng: -100.347591 }, // Punto 2
  //   { lat: 25.681346, lng: -100.347465 }, // Punto 3
  //   { lat: 25.681167, lng: -100.347453 }, // Punto 4
  // ];

  // const polygonPoints2 = [
  //   { lat: 25.648261, lng: -100.333385 }, // Punto 1
  //   { lat: 25.649217, lng: -100.332982 }, // Punto 2
  //   { lat: 25.649037, lng: -100.33247 }, // Punto 3
  //   { lat: 25.648086, lng: -100.332884 }, // Punto 4
  // ];

  const handlerCompany = (company_id: number) => {
    if (company_id > 0) {
      setCompanyId(company_id);
      handleZoomIn(company_id);
    } else {
      setMapCenter({ lat: 25.6484, lng: -100.3329708 });
      handleZoomOut();
    }
  };

  const handleZoomIn = (company_id: number) => {
    if (mapRef.current) {
      const bounds = new google.maps.LatLngBounds();
      let polygonPoints3 = [];
      if (companies.length) {
        polygonPoints3 = getSubarrayByCompanyId(company_id, companies);
      }
      polygonPoints3.forEach((point: any) => {
        bounds.extend(point);
      });
      mapRef.current.fitBounds(bounds);
    }
  };
  const handleZoomOut = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(12);
    }
  };

  const handleMapLoad = (map: any) => {
    mapRef.current = map;
  };

  const getSubarrayByCompanyId = (companyId: number, data: any): any[] => {
    for (const subarray of data) {
      const result = subarray.filter(
        (location: any) => location.company_id === companyId
      );
      if (result.length > 0) {
        return result;
      }
    }
    return [];
  };

  return (
    <>
      <div className="mb-10">
        <Filter onHandlerCompany={handlerCompany}></Filter>
      </div>
      <div className="card card-flush">
        <LoadScript googleMapsApiKey="AIzaSyAHr4sSYelDB1S9MUMeX8n8IfqrGFC7WOo">
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: `${mapHeight}px`,
              borderRadius: '10px',
            }}
            center={mapCenter}
            zoom={12}
            onLoad={handleMapLoad}
          >
            {mapRef.current &&
              guardHouse.map((marker, index) => (
                <Marker
                  key={marker.id}
                  position={marker.coords}
                  icon={{
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: marker.active ? '#36F566' : '#FD737D',
                    fillOpacity: 1,
                    strokeWeight: 2,
                    strokeColor: marker.active ? 'green' : 'red',
                    scale: 15,
                  }}
                  onClick={() => onHandlerGuardHouse(marker.id)}
                ></Marker>
              ))}

            {companies.map((coords, index) => (
              <Polygon
                paths={coords}
                key={index}
                options={{
                  strokeColor: '#FF0000',
                  fillColor: '#FF0000',
                  fillOpacity: 0.1,
                }}
              />
            ))}
          </GoogleMap>
        </LoadScript>
      </div>
    </>
  );
};

export { MonitoringMap };
