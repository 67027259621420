import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { useHistory } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useGetGuardHouseById } from '../hooks/MonitoringHook';

type Props = {
  show: boolean;
  guardHouse_id: number;
  handleClose: (updateGrid: boolean) => void;
};

const InfoEmployeeModal: React.FC<Props> = ({
  show,
  guardHouse_id,
  handleClose,
}) => {
  // const [loading, setLoading] = useState(false);
  // const [statusError, setStatusError] = useState('');
  const { initValues } = useGetGuardHouseById(guardHouse_id);

  const history = useHistory();

  const onHandlerClickLog = () => {
    history.push('/reportes/bitacora');
  };

  const showUsersInService = () => {
    const empleados: any[] = initValues.empleados;
    const empleadosActivos = empleados.filter((empleado) => empleado.active);
    const cantidadEmpleadosActivos = empleadosActivos.length;
    return cantidadEmpleadosActivos;
  };
  const showUsersOutService = () => {
    const empleados: any[] = initValues.empleados;
    const empleadosActivos = empleados.filter(
      (empleado) => empleado.active === false
    );
    const cantidadEmpleadosActivos = empleadosActivos.length;
    return cantidadEmpleadosActivos;
  };

  return (
    <Modal
      id="kt_modal_active_user"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-1000px"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      {initValues && (
        <div className="modal-content">
          <div className="modal-header " id="kt_modal_active_user_header">
            <h2> Información de la caseta</h2>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              onClick={() => {
                handleClose(false);
              }}
            >
              <KTSVG
                className="svg-icon-2"
                path="/media/icons/duotune/arrows/arr061.svg"
              />
            </div>
          </div>
          <div className="modal-body">
            <div className="col-sm-12">
              <div className="d-flex flex-column h-100">
                <div className="mb-7">
                  {/* <div className="d-flex flex-stack mb-6">
                  <div className="flex-shrink-0 me-5">
                    <span className="text-gray-400 fs-7 fw-bold me-2 d-block lh-1 pb-1">
                      Recursos necesarios:
                    </span>

                    <span className="text-gray-800 fs-1 fw-bold">
                      3 Recursos
                    </span>
                  </div>

                  <span className="badge badge-light-success flex-shrink-0 align-self-center py-3 px-4 fs-7">
                    Con recursos
                  </span>
                </div> */}
                  <div className="row mb-5">
                    <div className="w-25 pe-5">
                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                        Caseta:
                      </div>
                      {initValues.nombre}
                    </div>
                    <div className="w-25 pe-5">
                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                        Número de caseta:
                      </div>
                      {initValues.numero_caseta}
                    </div>
                    <div className="w-25 pe-5">
                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                        Recursos requeridos:
                      </div>
                      <div className="fw-bold fs-6 text-gray-800">
                        {initValues.num_recursos}
                      </div>
                    </div>
                    <div className="w-25 pe-5">
                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                        Recursos asignados:
                      </div>
                      <div className="fw-bold fs-6 text-gray-800">
                        {initValues.empleados.length}
                      </div>
                    </div>
                    <div className="w-25 pe-5 mt-5">
                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                        Teléfono:
                      </div>
                      <div className="fw-bold fs-6 text-gray-800">
                        {initValues.telefono}
                      </div>
                    </div>
                    <div className="w-25 pe-5 mt-5">
                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                        Ext:
                      </div>
                      <div className="fw-bold fs-6 text-gray-800">
                        {initValues.ext}
                      </div>
                    </div>
                    <div className="w-25 pe-5 mt-5">
                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                        Recursos en servicio:
                      </div>
                      <div className="fw-bold fs-6 text-gray-800">
                        {showUsersInService()}
                      </div>
                    </div>
                    <div className="w-25 pe-5 mt-5">
                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                        Recursos sin servicio:
                      </div>
                      <div className="fw-bold fs-6 text-gray-800">
                        {showUsersOutService()}
                      </div>
                    </div>
                  </div>

                  <div className="mt-15">
                    <div className="table-responsive">
                      <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                        <thead>
                          <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                            <th className="p-0 pb-3 w-500px text-start">
                              Recurso
                            </th>
                            {/* <th className="p-0 pb-3 min-w-100px text-start pe-7">
                              Hora entrada prog.
                            </th>
                            <th className="p-0 pb-3 min-w-100px text-start pe-7">
                              Hora salida prog.
                            </th> */}
                            {/* <th className="p-0 pb-3 min-w-100px text-start pe-7">
                              Hora entrada
                            </th>
                            <th className="p-0 pb-3 min-w-100px text-start pe-7">
                              Hora salida
                            </th> */}
                            <th className="p-0 pb-3 min-w-300px text-start pe-7">
                              Estatus
                            </th>
                            <th className="p-0 pb-3 w-250px text-end">
                              Bitácora
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {initValues.empleados.map((employee: any) => (
                            <tr key={employee.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-40px me-3">
                                    <img
                                      src={
                                        employee?.user?.profile_photo
                                          ? `${process.env.REACT_APP_PUBLIC}users/${employee?.user?.profile_photo}`
                                          : toAbsoluteUrl(
                                              '/media/avatars/blank.png'
                                            )
                                      }
                                      crossOrigin="anonymous"
                                      className=""
                                      alt=""
                                    />

                                    {/* <img
                                      src={toAbsoluteUrl(
                                        `/media/avatars/${employee.id}.jpg`
                                      )}
                                      crossOrigin="anonymous"
                                      className=""
                                      alt=""
                                    /> */}
                                  </div>

                                  <div className="d-flex justify-content-start flex-column">
                                    <a
                                      href="#/"
                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6"
                                    >
                                      {employee.nombre}{' '}
                                      {employee.apellido_paterno}
                                    </a>
                                    <span className="text-gray-400 fw-semibold d-block fs-7">
                                      {employee.telefono}
                                    </span>
                                  </div>
                                </div>
                              </td>

                              {/* <td className="text-start pe-13">
                                <span className="text-gray-600 fw-bold fs-6">
                                  7:00 a.m.
                                </span>
                              </td>

                              <td className="text-start pe-13">
                                <span className="text-gray-600 fw-bold fs-6">
                                  7:00 p.m.
                                </span>
                              </td> */}
                              {/* <td className="text-start pe-13">
                                <span className="text-gray-600 fw-bold fs-6">
                                  -
                                </span>
                              </td>

                              <td className="text-start pe-13">
                                <span className="text-gray-600 fw-bold fs-6">
                                  -
                                </span>
                              </td> */}

                              <td className="text-start pe-13">
                                {employee.active ? (
                                  <span className="badge badge-light-success py-2 px-4 fs-8">
                                    En servicio
                                  </span>
                                ) : (
                                  <span className="badge badge-light-danger py-2 px-4 fs-8">
                                    Sin servicio
                                  </span>
                                )}
                              </td>

                              <td className="text-end">
                                <button
                                  className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                                  onClick={onHandlerClickLog}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen005.svg"
                                    className="svg-icon-1 svg-icon-primary"
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center pt-8">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  handleClose(false);
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
export { InfoEmployeeModal };
