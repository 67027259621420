import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAll(
  filters: any
) {
  let id: string = '';
  if (filters) {

    if (filters.id > 0) {
      id = filters.id;
    }
  }
  const response = await axios.get(
    `${API_URL}caseta/empresa/${id}`
  );
  const data = response.data.doc.data;
  return data;
}