import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { ListGrid } from './ListGrid';
import { useGetAll } from '../hooks/GuardHouseHook';

type Props = {
  id: number;
};

const View: React.FC<Props> = ({ id }) => {
  // const [idRow, setIdRow] = useState(0);
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    id: id,
  });
  // const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    initFilterValues
  );

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div
            className={`card card-xxl-stretch mb-5 mb-xl-12`}
            style={{
              background: 'none',
            }}
          >
            <div className="card-header border-0">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  // onClick={handlerNewBranch}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nueva caseta
                </a>
              </div>
            </div>
            <ListGrid data={data} loadingGrid={loadingGrid} count={count} />
          </div>
        </div>
      </div>
      {/* <BranchCUModal
        id_row={idRow}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
          }
          setIdRow(0);
          setVisivilityModal(!visivilityModal);
        }}
      ></BranchCUModal> */}
    </>
  );
};

export { View };
