import { useState, useEffect } from 'react'
import { getTestReport, getTestReportFilters } from '../../../../service/reportes/Test'
// import { TestModel } from '../models/TestModel'
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, startIndex: number, endIndex: number, filters:any ) => {

    const [ data, setData ] = useState<any[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getTestReport( startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid ]);
    return { data, count, loadingRequest }

}

export const useGetAllFilters = (realoadGrid: number, filters:any ) => {

    const [ dataFilters, setDataFilters ] = useState<any[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getTestReportFilters(filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setDataFilters(res.rows);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ realoadGrid ]);
    return { dataFilters }

}
