import { useState, useEffect } from 'react'
import { getAllTest, getOneTest } from '../../../../service/catalog/Test'
import { TestModel } from '../../../reportes/log/models/TestModel'
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, startIndex: number, endIndex: number, filters:any ) => {

    const [ data, setData ] = useState<TestModel[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getAllTest( startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid ]);
    return { data, count, loadingRequest }
}

export const useGetOne = ( id:number ) => {
    const initModel = {
        id: 0,
        fecha: new Date(),
        tipo: "TIPO1",
        monto: 0,
        referencia: '',
        company_id:0
    }
    const [ initValues, setInitValues ] = useState<TestModel>(initModel);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getOneTest( id ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setInitValues(res);
            }
         };
         if( id ){
            fetchPost();
         } else {
            setInitValues(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ]);
    return { initValues }
}
