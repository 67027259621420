import { useState, useEffect } from 'react';
import {
    getSelect, getCompanyPolygon, getGuardHouse, getGuardHouseById
} from '../../../service/company/CompanyService';
import { CompanyModel } from '../../company/models/CompanyModel';
// import { PolygonCompanyModel } from '../models/MonitoringModel';
import { useHistory } from 'react-router-dom';

export const useSelect = () => {

    const [ data, setData ] = useState<CompanyModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getSelect( ).then().catch((error) => {
                history.push('/error/500')
            });
            if( data ){
                setData(data.rows);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { data }
}
export const useGetCompanyPolygon = () => {

    const [ data, setData ] = useState<any>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getCompanyPolygon( ).then().catch((error) => {
                history.push('/error/500')
            });
            if( data ){
                setData(data);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { data }
}
export const useGetGuardHouse = ( reload: number) => {

    const [ data, setData ] = useState<any>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getGuardHouse( ).then().catch((error) => {
                history.push('/error/500')
            });
            if( data ){
                setData(data);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    return { data }
}

export const useGetGuardHouseById = (id: number ) => {

    const [initValues, setInitValues] = useState<any>();
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        const res = await getGuardHouseById(id)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setInitValues(res);
        }
      };
      if (id) {
        fetchPost();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return { initValues };
  };
