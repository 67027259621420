import React from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components/MenuComponent';
import clsx from 'clsx';

type Props = {
  data: any;
  count: number;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({ data, count, loadingGrid }) => {
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const renderEstatus = (estatus: any) => {
    let classPer: string = '';
    switch (estatus.code) {
      case 'ACTIVADA':
        classPer = 'badge-light-success';
        break;
      case 'DESACTIVADA':
        classPer = 'badge-light-warning';
        break;
      default:
        classPer = 'badge-light-info';
        break;
    }
    return (
      <>
        <span className={clsx(`${classPer} badge fw-bold me-auto px-4 py-3`)}>
          {estatus.name}
        </span>
      </>
    );
  };

  const obtenerIniciales = (nombre: string) => {
    const palabras = nombre.split(' ');
    let iniciales = '';
    palabras.forEach((palabra) => {
      if (palabra.length > 0) {
        iniciales += palabra[0].toUpperCase();
      }
    });
    return iniciales.substring(0, 2);
  };

  const renderRecurso = (nombre: string) => {
    // const bgs = [
    //   'bg-primary',
    //   'bg-info',
    //   'bg-danger',
    //   'bg-warnign',
    //   'bg-secondary',
    // ];
    // const indiceAleatorio = Math.floor(Math.random() * bgs.length);
    // const valorAleatorio = bgs[indiceAleatorio];

    return (
      <>
        <>
          <span
            className={clsx(
              `bg-info symbol-label bg-primary text-inverse-primary fw-bold`
            )}
          >
            {obtenerIniciales(nombre)}
          </span>
          <span className="tooltiptext">{nombre}</span>
        </>
      </>
    );
  };

  return (
    <>
      <div className="py-3">
        <div className="row g-6 g-xl-9 min-h-600px">
          {loadingGrid ? (
            <div className="d-flex align-items-center">
              <div className="text-muted text-center fs-6 w-100 m-5">
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </div>
            </div>
          ) : count <= 0 ? (
            <div className="d-flex align-items-center">
              <div className=" text-center fs-6 w-100 m-5">
                <h1 className="text-gray-400">Aún no tienes casetas.</h1>
              </div>
            </div>
          ) : (
            data.map((row: any) => (
              <div className="col-md-3 col-xl-3" key={row.id}>
                <div
                  className="card border-hover-primary"
                  style={{ minHeight: '410px' }}
                >
                  <div className="card-header border-0 pt-9">
                    <div className="card-title m-0">
                      <div className="symbol symbol-50px w-50px bg-light">
                        <img
                          src={toAbsoluteUrl('/media/avatars/caseta.jpg')}
                          crossOrigin="anonymous"
                          alt="Logo"
                        ></img>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      {renderEstatus({
                        code: 'ACTIVADA',
                        name: 'En servicio',
                      })}
                    </div>
                  </div>
                  <div className="card-body p-8">
                    <div className="fs-3 fw-bold text-dark">{row.nombre}</div>
                    <p className="text-gray-400 fw-semibold fs-5 mt-1 mb-7">
                      {row.numero_caseta}
                    </p>
                    <div className="d-flex flex-wrap mb-5">
                      <div className="border border-gray-300 border-dashed rounded min-w-120px py-3 px-4 me-2 mb-3">
                        <div className="fs-7 text-gray-800 fw-bold">
                          Teléfono
                        </div>
                        <div className="fw-semibold text-gray-400 fs-7">
                          {row.telefono}
                        </div>
                      </div>
                      <div className="border border-gray-300 border-dashed rounded min-w-120px py-3 px-4 me-2 mb-3">
                        <div className="fs-7 text-gray-800 fw-bold">
                          Extensión
                        </div>
                        <div className="fw-semibold text-gray-400 fs-7">
                          {row.ext}
                        </div>
                      </div>
                      <div className="border border-gray-300 border-dashed rounded min-w-120px py-3 px-4 me-2 mb-3">
                        <div className="fs-7 text-gray-800 fw-bold">
                          Recursos
                        </div>
                        <div className="fw-semibold text-gray-400 fs-7">
                          {row.num_recursos}
                        </div>
                      </div>
                    </div>
                    <div className="fs-7 text-gray-800 fw-bold">
                      Recursos asigandos:
                    </div>
                    <div className="symbol-group symbol-hover mt-5">
                      {row.empleados.map((employee: any) => (
                        <div
                          className="symbol symbol-35px symbol-circle tooltip-soft"
                          key={employee.id}
                        >
                          {renderRecurso(
                            `${employee.nombre} ${employee.apellido_paterno}`
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export { ListGrid };
