import React from 'react';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../helpers';

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  return (
    <>
      {userHasRole(['SYSTEM_ADMIN', 'ADMIN_COMPANY', 'GUA'], userRoles) && (
        <AsideMenuItem
          to="/monitoreo"
          icon="/media/icons/duotune/maps/map004.svg"
          title={'Monitoreo'}
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['SYSTEM_ADMIN', 'ADMIN_COMPANY', 'GUA'], userRoles) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/general/gen005.svg"
          fontIcon="bi-layers"
          to="/reportes/"
          title="Reportes"
          hasBullet={false}
        >
          <AsideMenuItem
            to="/reportes/bitacora"
            icon="/media/icons/duotune/maps/map003.svg"
            title="Asistencia"
            fontIcon="bi-app-indicator"
          />
        </AsideMenuItemWithSub>
      )}
      {/* {userHasRole(['SYSTEM_ADMIN'], userRoles) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/general/gen054.svg"
          fontIcon="bi-layers"
          to="/catalogos/"
          title="Catálogos"
          hasBullet={false}
        >
          <AsideMenuItem
            to="/catalogos/prueba"
            icon="/media/icons/duotune/finance/fin010.svg"
            title="Prueba"
            fontIcon="bi-app-indicator"
          />
          <div className="menu-item">
            <div className="menu-content pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Generales
              </span>
            </div>
          </div>
          <AsideMenuItem
            to="/catalogos/generico/TEST"
            title="Genérico"
            icon="/media/icons/duotune/abstract/abs009.svg"
          />
        </AsideMenuItemWithSub>
      )} */}
      {userHasRole(['SYSTEM_ADMIN', 'ADMIN_COMPANY'], userRoles) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Administración
            </span>
          </div>
        </div>
      )}
      {userHasRole(['SYSTEM_ADMIN', 'ADMIN_COMPANY'], userRoles) && (
        <>
          <AsideMenuItem
            to="/empresas"
            title="Empresas"
            icon="/media/icons/duotune/communication/com001.svg"
          />
          <AsideMenuItem
            to="/empleados"
            title="Empleados"
            icon="/media/icons/duotune/general/gen049.svg"
          />
          {userHasRole(['SYSTEM_ADMIN'], userRoles) && (
            <>
              {/* <AsideMenuItem
                to="/usuarios"
                title="Usuarios del sistema"
                icon="/media/icons/duotune/general/gen049.svg"
              /> */}
              {/* <AsideMenuItem
                to="/empresas_sistema"
                title="Empresas del sistema"
                icon="/media/icons/duotune/communication/com001.svg"
              /> */}
            </>
          )}
        </>
      )}
    </>
  );
}
