import React, { useEffect, useState } from 'react';
import { KTSVG, SimpleAlert } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetRoles } from '../hooks/userHook';
import { addUser, updateUser } from '../../../service/users/Usuarios';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  user: any;
};
const userSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(20, 'El nombre no debe tener más de 20 caracteres')
    .required('Nombre es requerido.'),
  mother_last_name: Yup.string()
    .max(20, 'El apellido no debe tener más de 20 caracteres')
    .required('Apellido materno es requerido.'),
  father_last_name: Yup.string()
    .max(20, 'El apellido no debe tener más de 20 caracteres')
    .required('Apellido paterno es requerido.'),
  email: Yup.string()
    .email('Formato de correo inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.')
    .required('Correo es requerido.'),
  roles: Yup.array().min(1, 'Debe seleccionar al menos un role'),
});
const CreateUserModal: React.FC<Props> = ({
  show,
  handleClose,
  title,
  user,
}) => {
  const [userSelected, setUserSelected] = useState(user);
  const { roles } = useGetRoles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setUserSelected(user);
    }
  }, [user]);

  const formik = useFormik({
    initialValues: userSelected,
    validationSchema: userSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        // setLoading(false)

        if (values.id > 0) {
          updateUser(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              setLoading(false);
              formik.resetForm();
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        } else {
          addUser(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              setLoading(false);
              formik.resetForm();
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  const handleChecked = (array: any, item: any) => {
    let exist: boolean = false;
    if (array) {
      if (formik.values.roles.some((e: any) => e?.code === item.code)) {
        exist = true;
      }
    }
    return exist;
  };
  const handleAdd = (array: any, item: object) => {
    const newArray = [item];
    return newArray;
  };
  const handleDelete = (array: any, item: any) => {
    const newArray = array.filter((e: any) => e?.code !== item.code);
    return newArray;
  };
  return (
    <Modal
      id="kt_modal_create_user"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        formik.resetForm();
        handleClose(false);
      }}
      // onEntered={loadStepper}
    >
      <div className="modal-content">
        <div className="modal-header" id="kt_modal_add_user_header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formik.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_add_user_form"
            className="form"
            action="#"
            onSubmit={formik.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              {formik.status && (
                <div className="alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Nombre</label>
                  {formik.touched.first_name && formik.errors.first_name && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formik.errors.first_name}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Nombre"
                  {...formik.getFieldProps('first_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="first_name"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Apellido Paterno
                  </label>
                  {formik.touched.father_last_name &&
                    formik.errors.father_last_name && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">
                          {formik.errors.father_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Apellido Paterno"
                  {...formik.getFieldProps('father_last_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  disabled={formik.values.id > 0}
                  name="father_last_name"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Apellido Materno
                  </label>
                  {formik.touched.mother_last_name &&
                    formik.errors.mother_last_name && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">
                          {formik.errors.mother_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Apellido Materno"
                  {...formik.getFieldProps('mother_last_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="mother_last_name"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Correo</label>
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="example@domain.com"
                  {...formik.getFieldProps('email')}
                  className="form-control mb-3 mb-lg-0"
                  type="email"
                  name="email"
                  disabled={formik.values.id > 0}
                  autoComplete="off"
                />
              </div>
              {formik.values.id <= 0 && (
                <div className="mb-7">
                  <div className="d-flex fv-row">
                    <label className="required fw-bold fs-6 mb-5 me-5">
                      Role
                    </label>
                    {formik.touched.roles && formik.errors.roles && (
                      <div className="fv-plugins-message-container text-danger">
                        <span role="alert">{formik.errors.roles}</span>
                      </div>
                    )}
                  </div>

                  {roles.map((item, index) => (
                    <div key={item.id}>
                      <div className="d-flex fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            value={item.code}
                            name="roles"
                            onChange={(event) => {
                              const value = event.target.checked;
                              if (value) {
                                formik.setFieldValue(
                                  `roles`,
                                  handleAdd(formik.values.roles, item)
                                );
                              } else {
                                formik.setFieldValue(
                                  `roles`,
                                  handleDelete(formik.values.roles, item)
                                );
                              }
                            }}
                            checked={handleChecked(formik.values.roles, item)}
                          />
                          <label className="form-check-label">
                            <div className="fw-bolder text-gray-800">
                              {item.name}
                            </div>
                            <div className="text-gray-600">
                              {item.description}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-5"></div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formik.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateUserModal };
