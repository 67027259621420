import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelect } from '../hooks/MonitoringHook';

type Props = {
  onHandlerCompany: (company_id: number) => void;
};

const filterSchema = Yup.object().shape({});

const Filter: React.FC<Props> = ({ onHandlerCompany }) => {
  // const [loading, setLoading] = useState<boolean>(false);
  // const [loadingSelect, setLoadingSelect] = useState<boolean>(false);
  const { data: companies } = useSelect();

  const formFilter = useFormik({
    initialValues: {
      company_id: 0,
      employee_id: 0,
    },
    validationSchema: filterSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // setLoading(true);
      const { company_id } = values;
      // setTimeout(() => {
      onHandlerCompany(company_id);
      // setLoading(false);
      // }, 1000);
    },
  });

  const handlerCompany = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = Number(event.target.value);
    formFilter.setFieldValue('company_id', id);
    formFilter.submitForm();
  };

  return (
    <>
      <div className="card-title align-items-start flex-column w-100">
        <form id="kt_modal_generic_form" className="form w-100" action="#">
          <div className="d-flex flex-column flex-xl-row flex-row-fluid">
            <div className="w-100 d-flex">
              <div className="w-50 pe-5">
                <div className="w-25 d-flex">
                  <label className="fw-bold fs-6 mb-2 w-auto">Empresa</label>
                </div>
                <select
                  className="form-select mb-3 w-50"
                  onChange={handlerCompany}
                >
                  <option value={0}>Seleccione una empresa</option>
                  {companies.map((company: any) => (
                    <option value={company.id} key={company.id}>
                      {company.nombre}
                    </option>
                  ))}

                  {/* <option value={2}>Torre Comercial America</option> */}
                </select>
              </div>
              {/* <div className="w-25 pe-5">
                <div className="w-100 d-flex">
                  <label className=" fw-bold fs-6 mb-2 w-auto">Empleado</label>
                </div>

                <select className="form-select mb-3">
                  <option value={0}>Todos</option>
                  <option value={1}>Adrián Rpdriguez (897192). </option>
                  <option value={2}>Jorge Aranda (1823761). </option>
                </select>
              </div> */}
              {/* <div className="w-50 text-end">
                <button
                  type="button"
                  onClick={() => {
                    formFilter.submitForm();
                  }}
                  className="btn btn-sm btn-primary mt-10"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && <span className="indicator-label">Buscar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Buscando...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div> */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export { Filter };
