import React, { useState } from 'react';
import {
  KTSVG,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
} from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  addRegister,
  updateRegister,
} from '../../../service/employee/EmployeeService';
import { useGetOne } from '../hooks/EmployeeHook';
import { NumericFormat } from 'react-number-format';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const EmpleadoSchema = Yup.object().shape({
  numero_empleado: Yup.number()
    .min(1, 'Requerido')
    .max(999999, 'Máximo 6 caracteres')
    .required('Requerido.'),
  nombre: Yup.string()
    .max(100, 'Máximo 100 caracteres')
    .required('Nombre requerido.'),
  apellido_paterno: Yup.string()
    .max(100, 'Máximo 100 caracteres')
    .required('Apellido requerido.'),
  apellido_materno: Yup.string().max(100, 'Máximo 100 caracteres'),
  fecha_nacimiento: Yup.date().required('Fecha nacimiento requerido.'),
  email: Yup.string()
    .email('Formato de correo inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.')
    .required('Correo requerido.'),
  telefono: Yup.string()
    .required('Teléfono requerido.')
    .min(10, 'Formato de teléfono inválido'),
  genero: Yup.object().shape({
    id: Yup.number().min(1, 'Genero requerido.').required('Genero requerido.'),
  }),
});

const CreateUpdateModal: React.FC<Props> = ({
  id_row,
  show,
  handleClose,
  title,
}) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const { catalog: genders } = useSelectGeneric('GEN');

  const formEmpleado = useFormik({
    initialValues: initValues,
    validationSchema: EmpleadoSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateRegister(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formEmpleado.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        } else {
          addRegister(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formEmpleado.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';

              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    formEmpleado.setFieldValue('telefono', cleanPhoneNumber(value));
    formEmpleado.setFieldValue('telefonoFormat', maskedPhoneNumber);
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={show}
      onHide={() => {
        formEmpleado.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              formEmpleado.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">{title}</h1>
            <div className="text-muted fw-semibold fs-5">
              Agrega la información del empleado.
            </div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formEmpleado.handleSubmit}
          >
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="w-100 d-flex">
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Nombre
                    </label>
                    {formEmpleado.touched.nombre && formEmpleado.errors.nombre && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">{formEmpleado.errors.nombre}</span>
                      </div>
                    )}
                  </div>
                  <input
                    placeholder="Nombre del empleado"
                    {...formEmpleado.getFieldProps('nombre')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="nombre"
                    autoComplete="off"
                  />
                </div>
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-40">
                      Apellido Paterno
                    </label>
                    {formEmpleado.touched.apellido_paterno &&
                      formEmpleado.errors.apellido_paterno && (
                        <div className="fv-plugins-message-container text-danger w-50 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.apellido_paterno}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    placeholder="Apellido Paterno"
                    {...formEmpleado.getFieldProps('apellido_paterno')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="apellido_paterno"
                    autoComplete="off"
                  />
                </div>
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className=" fw-bold fs-6 mb-2 w-40">
                      Apellido Materno
                    </label>
                    {formEmpleado.touched.apellido_materno &&
                      formEmpleado.errors.apellido_materno && (
                        <div className="fv-plugins-message-container text-danger w-60 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.apellido_materno}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    placeholder="Apellido Materno"
                    {...formEmpleado.getFieldProps('apellido_materno')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="apellido_materno"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5">
              <div className="w-100 d-flex">
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2">
                      No. de empleado
                    </label>
                    {formEmpleado.touched.numero_empleado &&
                      formEmpleado.errors.numero_empleado && (
                        <div className="fv-plugins-message-container text-danger ms-5">
                          <span role="alert">
                            {formEmpleado.errors.numero_empleado}
                          </span>
                        </div>
                      )}
                  </div>
                  <NumericFormat
                    name="employee_number"
                    className="form-control"
                    value={formEmpleado.values.numero_empleado}
                    decimalScale={0}
                    decimalSeparator="."
                    maxLength={6}
                    fixedDecimalScale
                    placeholder="Número de empleado"
                    prefix=""
                    thousandSeparator=""
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      formEmpleado.setFieldValue(
                        'numero_empleado',
                        floatValue ? floatValue : 0
                      );
                    }}
                  />
                </div>

                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Genero
                    </label>
                    {formEmpleado.touched.genero?.id &&
                      formEmpleado.errors.genero?.id && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.genero.id}
                          </span>
                        </div>
                      )}
                  </div>
                  <select
                    className="form-select"
                    {...formEmpleado.getFieldProps('genero.id')}
                  >
                    <option value={0}>Seleccione</option>
                    {genders.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Fecha de nacimiento
                    </label>
                    {formEmpleado.touched.fecha_nacimiento &&
                      formEmpleado.errors.fecha_nacimiento && (
                        <div className="fv-plugins-message-container text-danger w-50 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.fecha_nacimiento}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    value={
                      new Date(formEmpleado.values.fecha_nacimiento)
                        .toISOString()
                        .split('T')[0]
                    }
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        formEmpleado.setFieldValue('fecha_nacimiento', fecha);
                      }
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5">
              <div className="w-100 d-flex">
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-40">
                      Correo
                    </label>
                    {formEmpleado.touched.email && formEmpleado.errors.email && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">{formEmpleado.errors.email}</span>
                      </div>
                    )}
                  </div>
                  <input
                    placeholder="Correo"
                    {...formEmpleado.getFieldProps('email')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="email"
                    autoComplete="off"
                  />
                </div>
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-40">
                      Teléfono
                    </label>
                    {formEmpleado.touched.telefono &&
                      formEmpleado.errors.telefono && (
                        <div className="fv-plugins-message-container text-danger w-60 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.telefono}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    value={formEmpleado.values.telefonoFormat}
                    placeholder="Teléfono"
                    prefix=""
                    onChange={handlePhoneChange}
                  />
                </div>
              </div>
            </div>

            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formEmpleado.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateUpdateModal };
