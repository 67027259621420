import { useState, useEffect } from 'react';
import {
  getAll, getOne
} from '../../../service/employee/EmployeeService';
import { EmployeeModel } from '../models/EmployeeModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<EmployeeModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAll(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endIndex, realoadGrid]);
  return { data, count, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    numero_empleado: "",
    email: "",
    telefono: "",
    telefonoFormat: "",
    fecha_nacimiento: new Date(),
    created_at: new Date(),
    tipoEmpleado: {
        id: 0,
        name: "",
        code: "",
    },
    genero: {
        id: 0,
        name: "",
        code: "",
    }
  };
  const [initValues, setInitValues] = useState<EmployeeModel>(initModel);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getOne(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { initValues };
};