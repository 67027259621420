import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getTestReport( startIndex:number, endIndex:number, filters:any ) {
    let search : string = ''; 
    const response = await axios.get(`${API_URL}bitacoraAsistencia?limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;
}
export async function getTestReportFilters( filters:any ) {
    const response = await axios.get(`${API_URL}bitacoraAsistencia`);
    const data = response.data.doc.data;
    return data;
}