import React, { useState, useEffect } from 'react';
import { MonitoringMap } from './MonitoringMap';
import { InfoEmployeeModal } from '../_modals/InfoEmployeeModal';
import {
  useGetCompanyPolygon,
  useGetGuardHouse,
} from '../hooks/MonitoringHook';

const MonitoringPage: React.FC = () => {
  const [guardHouse_id, setGuardHouse_id] = useState(0);
  const [refresh, setRefresh] = useState(Math.random() * 40);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const { data: companies } = useGetCompanyPolygon();
  const { data: guardHouse } = useGetGuardHouse(refresh);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(Math.random() * 40);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onHandlerGuardHouse = (guardHouse_id: number) => {
    setGuardHouse_id(guardHouse_id);
    setVisivilityModal(!visivilityModal);
  };

  return (
    <>
      <div className="row g-6 g-xl-9">
        <div className="col-xl-12">
          <MonitoringMap
            onHandlerGuardHouse={onHandlerGuardHouse}
            companies={companies}
            guardHouse={guardHouse}
          />
        </div>
      </div>
      <InfoEmployeeModal
        show={visivilityModal}
        guardHouse_id={guardHouse_id}
        handleClose={(updateGrid: boolean) => {
          setGuardHouse_id(0);
          setVisivilityModal(!visivilityModal);
        }}
      ></InfoEmployeeModal>
    </>
  );
};

export { MonitoringPage };
