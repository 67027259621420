import { useState, useEffect } from 'react';
import {
  getAll
} from '../../../../service/company/GuardHouseService';
import { GuardHouseModel } from '../models/GuardHouseModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  filters: any
) => {
  const [data, setData] = useState<GuardHouseModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAll(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setCount(res.length);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { data, count, loadingRequest };
};