import { useState, useEffect } from 'react';
import {
  getAllEmpresas,
  getOneEmpresas,
  getSelectEmpresas,
} from '../../../service/empresas/Empresas';
import { EmpresaModel } from '../models/EmpresaModel';
import { useHistory } from 'react-router-dom';

export const useSelectEmpresas = () => {
  const [empresas, setEmpresas] = useState<EmpresaModel[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getSelectEmpresas()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setEmpresas(res);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { empresas };
};

export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<EmpresaModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllEmpresas(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endIndex, realoadGrid]);
  return { data, count, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    code: '',
    name: '',
  };
  const [initValues, setInitValues] = useState<EmpresaModel>(initModel);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getOneEmpresas(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { initValues };
};
// export const useSelect = ( ) => {

//     const [ data, setData ] = useState<ClienteModel[]>([]);
//     const history = useHistory()
//     useEffect(() => {
//         const fetchPost = async () => {
//             const res = await getSelectTipoCP().then().catch((error) => {
//                 history.push('/error/500')
//             });
//             if( res ){
//                 setData(res.rows);
//             }
//          };
//          fetchPost();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     return { data }
// }
