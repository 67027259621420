import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const EmployeePage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Empleados</PageTitle>
      <View />
    </>
  );
};

export { EmployeePage };
