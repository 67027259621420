import React from 'react';
// import { Redirect, Route, Switch } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import { View } from './components/View';

// import { useGetOne } from './hooks/ProyectosHook';

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Empresas',
    path: '/empresas/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const GuardHousePage: React.FC = () => {
  let { id }: { id: string } = useParams();
  // const [reloadInfo, setReloadInfo] = useState(false);
  // const { initValues } = useGetOne(Number(id), reloadInfo);

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Casetas</PageTitle>
      <View id={Number(id)} />
    </>
  );
};

export { GuardHousePage };
