import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { FallbackView } from '../../_metronic/partials';
import { userHasRole } from '../../_metronic/helpers';
import { UserModel } from '../modules/auth/models/UserModel';
import { RootState } from '../../setup';
import { MonitoringWrapper } from '../pages/dashboard/MonitoringWrapper';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { UsuariosPage } from '../pages/usuarios/Usuarios';
import { GenericoPage } from '../pages/catalogos/generico/Generico';
import { ReportLogPage } from '../pages/reportes/log/LogWrapper';
import { TestPage } from '../pages/catalogos/test';
import { EmpresasPage } from '../pages/empresas/Empresas';
import { CompanyPage } from '../pages/company/CompanyWrapper';
import { EmployeePage } from '../pages/employee/EmployeeWrapper';
import { GuardHousePage } from '../pages/company/guardHouse/GuardHouseWrapper';

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* DASHBOARD */}
        <Route
          path="/monitoreo"
          component={
            userHasRole(['SYSTEM_ADMIN', 'ADMIN_COMPANY', 'GUA'], userRoles)
              ? MonitoringWrapper
              : ErrorsPage
          }
        />

        {/* REPORTES */}
        <Route
          path="/reportes/bitacora"
          component={
            userHasRole(['SYSTEM_ADMIN', 'ADMIN_COMPANY'], userRoles)
              ? ReportLogPage
              : ErrorsPage
          }
        />
        {/* CATALOGOS */}
        <Route
          path="/catalogos/prueba/"
          component={
            userHasRole(['SYSTEM_ADMIN'], userRoles) ? TestPage : ErrorsPage
          }
        />
        <Route
          path="/catalogos/generico/:code"
          component={
            userHasRole(['SYSTEM_ADMIN'], userRoles) ? GenericoPage : ErrorsPage
          }
        />
        {/* USUARIOS DEL SISTEMA */}
        <Route
          path="/empleados"
          component={
            userHasRole(['SYSTEM_ADMIN', 'ADMIN_COMPANY'], userRoles)
              ? EmployeePage
              : ErrorsPage
          }
        />
        <Route
          path="/empresas/"
          component={
            userHasRole(['SYSTEM_ADMIN', 'ADMIN_COMPANY'], userRoles)
              ? CompanyPage
              : ErrorsPage
          }
        />
        <Route
          path="/empresas-casetas/:id"
          component={
            userHasRole(['SYSTEM_ADMIN', 'ADMIN_COMPANY'], userRoles)
              ? GuardHousePage
              : ErrorsPage
          }
        />
        <Route
          path="/usuarios"
          component={
            userHasRole(['SYSTEM_ADMIN'], userRoles) ? UsuariosPage : ErrorsPage
          }
        />
        <Route
          path="/empresas_sistema"
          component={
            userHasRole(['SYSTEM_ADMIN'], userRoles) ? EmpresasPage : ErrorsPage
          }
        />
        <Route path="/perfil" component={ProfilePage} />
        <Redirect from="/auth" to="/monitoreo" />
        <Redirect exact from="/" to="/monitoreo" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
