import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import {
  KTSVG,
  toAbsoluteUrl,
  Pagination,
} from '../../../../_metronic/helpers';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { CreateUserModal } from '../_modals/CreateUserModal';
import { ActiveUserModal } from '../_modals/ActiveUserModal';
import { AddRolesUserModal } from '../_modals/AddRolesUserModal';
import { useGetAll } from '../hooks/userHook';
import { RootState } from '../../../../setup';

type Props = {
  className: string;
};

let initUser: UserModel = {
  id: 0,
  alias: '',
  first_name: '',
  father_last_name: '',
  mother_last_name: '',
  email: '',
  phone: '',
  profile_photo: '',
  company: '',
  active: false,
  roles: [],
};

const ListGrid: React.FC<Props> = ({ className }) => {
  const [loadingGrid, setLoadingGrid] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const pageSize = 10;
  const { users, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage
  );
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  useEffect(() => {
    setReloadGrid(Math.random() * 40);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const [userSelected, setUserSelected] = useState<UserModel>(initUser);
  const [userTitleModal, setUserTitleModal] = useState('');
  const [activeTitleModal, setActiveTitleModal] = useState('');
  const [activeDescriptionModal, setActiveDescriptionModal] = useState('');
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityActiveModal, setVisivilityActiveModal] = useState(false);
  const [visivilityRolesModal, setVisivilityRolesModal] = useState(false);
  const [userCurrentId, setUserCurrentId] = useState(0);

  const userCurrent: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const activeUser = (id: number, active: boolean, user: any) => {
    if (active) {
      setActiveTitleModal('Desactivar');
      setActiveDescriptionModal(
        'Si confirma está acción el usuario no podrá acceder al sistema.'
      );
    } else {
      setActiveTitleModal('Activar');
      setActiveDescriptionModal(
        'Si confirma está acción el usuario podrá acceder al sistema.'
      );
    }
    setUserSelected(user);
    setVisivilityActiveModal(!visivilityActiveModal);
  };
  const updateUser = (id: number, user: any) => {
    setReloadGrid(Math.random() * 40);
    setUserSelected(user);
    setUserTitleModal('Editar usuario');
    setVisivilityModal(!visivilityModal);
  };
  const updateRoles = (id: number, user: any, userCurrentId: number) => {
    setReloadGrid(Math.random() * 40);
    setUserSelected(user);
    setVisivilityRolesModal(!visivilityRolesModal);
    setUserCurrentId(userCurrentId);
  };
  const addUser = () => {
    setReloadGrid(Math.random() * 40);
    setUserSelected(initUser);
    setUserTitleModal('Agregar usuario');
    setVisivilityModal(!visivilityModal);
  };

  const Usuarios: Array<UserModel> = users;

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5">
        <div className="card-title align-items-start flex-column"></div>
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Nuevo registro"
        >
          <a
            href="#/"
            className="btn btn-sm btn-light-primary"
            id="kt_toolbar_primary_button"
            onClick={() => {
              addUser();
            }}
          >
            <KTSVG
              path="media/icons/duotune/arrows/arr075.svg"
              className="svg-icon-3"
            />
            Nuevo
          </a>
        </div>
      </div>
      <div className="card-body py-3">
        <div className="table-responsive min-h-600px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-100px"></th>
                <th className="min-w-200px">Nombre</th>
                <th className="min-w-200px">Apellido</th>
                <th className="min-w-200px">Correo</th>
                <th className="min-w-100px">Rol</th>
                <th className="min-w-100px">Estatus</th>
                <th className=" text-end"></th>
              </tr>
            </thead>
            <tbody>
              {loadingGrid ? (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : count <= 0 ? (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                Usuarios.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          <div className="symbol symbol-50px me-5">
                            <img
                              src={
                                user?.profile_photo
                                  ? `${process.env.REACT_APP_PUBLIC}users/${user?.profile_photo}`
                                  : toAbsoluteUrl('/media/avatars/blank.png')
                              }
                              crossOrigin="anonymous"
                              className=""
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          {user.first_name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          {user.father_last_name}
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          {user.email}
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          {user?.roles?.map((rol: any, index: number) => (
                            <span
                              key={index}
                              className="badge badge-light-primary"
                            >
                              {rol.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          {user.active ? (
                            <span className="badge badge-light-success">
                              Activo
                            </span>
                          ) : (
                            <span className="badge badge-light-danger text-muted">
                              Desactivado
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-end flex-shrink-0">
                        <a
                          href="#/"
                          className="btn btn-light btn-active-light-primary btn-sm"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          Acciones
                          <span className="svg-icon svg-icon-5 m-0">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mh-50px"
                            >
                              <path
                                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </a>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                          data-kt-menu="true"
                        >
                          <div className="menu-item px-3">
                            <a
                              className="menu-link px-3"
                              href="#/"
                              onClick={() => {
                                updateUser(user.id, user);
                              }}
                            >
                              Editar
                            </a>
                          </div>
                          <div className="menu-item px-3">
                            <a
                              className="menu-link px-3"
                              href="#/"
                              onClick={() => {
                                updateRoles(user.id, user, userCurrent.id);
                              }}
                            >
                              Roles
                            </a>
                          </div>
                          {userCurrent.id !== user.id && (
                            <div className="menu-item px-3">
                              <a
                                className="menu-link px-3"
                                data-kt-users-table-filter="delete_row"
                                href="#/"
                                onClick={() => {
                                  activeUser(user.id, user.active, user);
                                }}
                              >
                                {user.active ? 'Desactivar' : 'Activar'}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">
            Total de registros: {Usuarios.length}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(pageNumber: number) => {
              setLoadingGrid(true);
              setCurrentPage(pageNumber);
            }}
          ></Pagination>
        </div>
      </div>
      <CreateUserModal
        title={userTitleModal}
        show={visivilityModal}
        user={userSelected}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
          }
          setUserSelected(initUser);
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateUserModal>
      <ActiveUserModal
        title={activeTitleModal}
        description={activeDescriptionModal}
        show={visivilityActiveModal}
        user={userSelected}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            const newPage = Math.ceil(
              (count - 1 <= 0 ? 1 : count - 1) / pageSize
            );
            setCurrentPage(newPage);
            setReloadGrid(Math.random() * 40);
          }
          setUserSelected(initUser);
          setVisivilityActiveModal(!visivilityActiveModal);
        }}
      ></ActiveUserModal>
      <AddRolesUserModal
        userCurrentId={userCurrentId}
        show={visivilityRolesModal}
        id={userSelected.id}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
          }
          setUserSelected(initUser);
          setVisivilityRolesModal(!visivilityRolesModal);
        }}
      ></AddRolesUserModal>
    </div>
  );
};

export { ListGrid };
