import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAll(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.nombre !== '') {
      search += `&nombreOpLike=%${filters.nombre}%`;
    }
  }
  const response = await axios.get(
    `${API_URL}empresaCliente?limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function getOne( id_row: number) {
  const response = await axios.get(`${API_URL}empresaCliente/${id_row}`);
  const { id, nombre, code, razon_social, telefono, email, nombre_contacto, nombre_responsable,
    calle, numero_exterior, numero_interior, colonia, municipio, estado, codigo_postal, 
    pais, longitud, latitud, sitio_web, industria
  } = response.data.doc.data;
  return {
    id: id ||  0,
    nombre: nombre ||  "",
    code: code ||  "",
    razon_social: razon_social ||  "",
    telefono: telefono ||  "",
    email: email ||  "",
    nombre_contacto: nombre_contacto ||  "",
    nombre_responsable: nombre_responsable ||  "",
    calle: calle ||  "",
    numero_exterior: numero_exterior ||  "",
    numero_interior: numero_interior ||  "",
    colonia: colonia ||  "",
    municipio: municipio ||  "",
    estado: estado ||  "",
    codigo_postal: codigo_postal ||  "",
    pais: pais ||  "",
    longitud: longitud | 0,
    latitud: latitud | 0,
    sitio_web: sitio_web ||  "",
    industria: {
        id: industria.id | 0,
        name: industria.name ||  "",
        code: industria.code ||  "",
    }
  };

}

export async function getSelect() {
  const response = await axios.get(`${API_URL}empresaCliente/`);
  const data = response.data.doc.data;
  return data;
}
export async function getCompanyPolygon() {
  const response = await axios.get(`${API_URL}empresaCliente/`);
  const data = response.data.doc.data;
  const companies = data.rows;

  const posicionesPorEmpresa: { latitud: number, longitud: number }[] = [];

  companies.forEach(( company: any, index: number ) => {
    const posiciones = company.geocercaEmpresa.map((geocerca: any) => ({
      company_id: company.id,
      lat: geocerca.latitud,
      lng: geocerca.longitud
    }));
  
    posicionesPorEmpresa[index] = posiciones;
  });
  return posicionesPorEmpresa;
}

export async function getGuardHouse() {
  const response = await axios.get(`${API_URL}caseta/`);
  const data = response.data.doc.data;
  return data;
}

export async function getGuardHouseById(id_row: number) {
  const response = await axios.get(`${API_URL}caseta/${id_row}`);
  let data = response.data.doc.data;
  const empleados = data.empleados;
  data.empleados = empleados.sort(compararPorNombre);
  return data;
}

const compararPorNombre = (a:any, b:any) => {
  if (a.active && !b.active) {
    return -1; // Si 'a' está activo y 'b' no, 'a' va primero
} else if (!a.active && b.active) {
    return 1; // Si 'b' está activo y 'a' no, 'b' va primero
} else {
    // Si ambos están activos o inactivos, ordenar por nombre
    return a.nombre.localeCompare(b.nombre);
}
};