import React, { useState, useEffect } from 'react';
import { ListGrid } from './ListGrid';
import { useGetAll, useGetAllFilters } from '../hooks/TestHook';
import { DownloadExcel } from '../../../../../_metronic/helpers';
import { KTSVG } from '../../../../../_metronic/helpers';

let fechaActual = new Date();
let fecha_inicio_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth(),
  1
);
let fecha_fin_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth() + 1,
  -1
);

const View: React.FC = () => {
  const [loadingGrid, setLoadingGrid] = useState(true);

  const [reloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    contrato: 0,
    plaza_id: -1,
    arrendatario_id: 0,
    fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
    fecha_fin: fecha_fin_init.toISOString().split('T')[0],
  });
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );
  const { dataFilters } = useGetAllFilters(reloadGrid, initFilterValues);
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;
  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const formatDateHours = (date: any) => {
    const fecha = new Date(date);

    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Sumamos 1 porque los meses comienzan en 0
    const año = fecha.getFullYear();
    const hora = fecha.getHours().toString().padStart(2, '0');
    const minuto = fecha.getMinutes().toString().padStart(2, '0');
    const segundo = fecha.getSeconds().toString().padStart(2, '0');

    return date ? `${dia}/${mes}/${año} ${hora}:${minuto}:${segundo}` : '-';
  };

  const download = () => {
    let columnsName = [
      'EMPLEADO',
      'CASETA',
      'ENTRADA',
      'SALIDA',
      'HORAS TRABAJADAS',
    ];
    let dataExcel: any = dataFilters.map((item) => {
      return {
        empleado: `${item.empleado.nombre} ${item.empleado.apellido_paterno} ${item.empleado.apellido_materno} `,
        caseta: item.caseta.nombre,
        entrada: formatDateHours(item.entrada),
        salida: formatDateHours(item.salida),
        horas: item.tiempo_transcurrido ? item.tiempo_transcurrido : '-',
      };
    });
    const response = {
      sheetName: 'REPORTE_ASISTENCA',
      fileName: 'REPORTE_ASISTENCIA.xlsx',
      title: 'REPORTE ASISTENCIA',
      columnsName: columnsName,
      columns: [
        {
          key: 'empleado',
          width: 30,
        },
        {
          key: 'caseta',
          width: 30,
        },
        {
          key: 'entrada',
          width: 20,
        },
        {
          key: 'salida',
          width: 20,
        },
        {
          key: 'horas',
          width: 20,
        },
      ],
      data: dataExcel,
    };
    DownloadExcel(response);
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-success ms-5"
                  title="Descargar excel"
                  onClick={download}
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil009.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Excel
                </a>
              </div>
            </div>
            <ListGrid
              data={data}
              loadingGrid={loadingGrid}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
