import axios from 'axios';
import { maskPhoneNumber, cleanPhoneNumber } from '../../../_metronic/helpers';
import { EmployeeModel } from '../../pages/employee/models/EmployeeModel';
const API_URL = process.env.REACT_APP_API;

export async function getAll(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.nombre !== '') {
      search += `&numero_empleadoOpLike=%${filters.numero_empleado}%`;
    }
  }
  const response = await axios.get(
    `${API_URL}empleados?limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export const addRegister = async (values: EmployeeModel) => {
  const { numero_empleado, nombre, apellido_paterno, apellido_materno, email, telefono, fecha_nacimiento, genero  } = values;
  const data = {
    numero_empleado: numero_empleado,
    nombre: nombre,
    apellido_paterno: apellido_paterno,
    apellido_materno: apellido_materno,
    email: email,
    telefono: telefono,
    fecha_nacimiento: fecha_nacimiento,
    genero_id: genero.id
  };
  const response = await axios.post(`${API_URL}empleados`, data);
  return response;
}
export async function updateRegister(body: any) {
  const { code, name, id } = body;
  const data = {
    code: code,
    name: name,
  };
  const response = await axios.put(`${API_URL}empleados/${id}`, data);
  return response;
}
export async function getOne(id_row: number) {
  const response = await axios.get(`${API_URL}empleados/${id_row}`);
  const { id, nombre, apellido_paterno, apellido_materno, numero_empleado, email, fecha_nacimiento,
    telefono, created_at, genero, 
     tipoEmpleado } = response.data.doc.data;
  return {
    id: id || 0,
    nombre: nombre || '',
    apellido_paterno: apellido_paterno || '',
    apellido_materno: apellido_materno || '',
    numero_empleado: numero_empleado || '',
    email: email || '',
    telefono: cleanPhoneNumber(telefono) || '',
    telefonoFormat: maskPhoneNumber(telefono) || '',
    fecha_nacimiento: fecha_nacimiento || new Date(),
    created_at: created_at || new Date(),
    tipoEmpleado: {
        id: tipoEmpleado.id,
        name: tipoEmpleado.name,
        code: tipoEmpleado.code,
    },
    genero: {
        id: genero.id,
        name: genero.name,
        code: genero.code,
    }
  };
}
export async function removeRegister(id: number) {
  const response = await axios.delete(`${API_URL}empleados/${id}`);
  return response;
}
