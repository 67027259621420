import React from 'react';
import { Pagination } from '../../../../../_metronic/helpers';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components/MenuComponent';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onPageChange,
  loadingGrid,
}) => {
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const formatDate = (date: any) => {
    const fecha = new Date(date);

    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Sumamos 1 porque los meses comienzan en 0
    const año = fecha.getFullYear();
    const hora = fecha.getHours().toString().padStart(2, '0');
    const minuto = fecha.getMinutes().toString().padStart(2, '0');
    const segundo = fecha.getSeconds().toString().padStart(2, '0');

    return (
      <>
        <div className="d-flex justify-content-start flex-column fs-6">
          {date ? `${dia}/${mes}/${año} ${hora}:${minuto}:${segundo}` : '-'}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card-body py-3">
        <div className="table-responsive min-h-600px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="w-200px">Empleado</th>
                <th className="w-200px">Caseta</th>
                <th className="w-150px">Entrada</th>
                <th className="w-150px">Salida</th>
                <th className="w-150px">Horas trabajadas</th>
              </tr>
            </thead>
            <tbody>
              {loadingGrid ? (
                <tr>
                  <td colSpan={4}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : count <= 0 ? (
                <tr>
                  <td colSpan={4}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((row: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.empleado.nombre} {row.empleado.apellido_paterno}{' '}
                          {row.empleado.apellido_materno}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.caseta.nombre}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        {formatDate(row.entrada)}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {formatDate(row.salida)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.tiempo_transcurrido
                            ? row.tiempo_transcurrido
                            : '-'}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
