import React, { useState } from 'react';
import { KTSVG, SimpleAlert } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  addEmpresas,
  updateEmpresas,
} from '../../../service/empresas/Empresas';
import { useGetOne } from '../hooks/EmpresasHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const EmpresaSchema = Yup.object().shape({
  code: Yup.string()
    .max(6, 'No puede tener más de 6 caracteres')
    .required('Código de la empresa es requerido.'),
  name: Yup.string()
    .max(200, 'No puede tener más de 200 caracteres')
    .required('Nombre de la empresa es requerido.'),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);

  const formEmpresa = useFormik({
    initialValues: initValues,
    validationSchema: EmpresaSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateEmpresas(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formEmpresa.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        } else {
          addEmpresas(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formEmpresa.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-550px"
      show={show}
      onHide={() => {
        formEmpresa.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formEmpresa.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formEmpresa.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="fv-row mb-5">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Código
                  </label>
                  {formEmpresa.touched.code && formEmpresa.errors.code && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formEmpresa.errors.code}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Código de la empresa"
                  {...formEmpresa.getFieldProps('code')}
                  className="form-control"
                  type="text"
                  name="code"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Nombre
                  </label>
                  {formEmpresa.touched.name && formEmpresa.errors.name && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formEmpresa.errors.name}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Nombre de la empresa"
                  {...formEmpresa.getFieldProps('name')}
                  className="form-control"
                  type="text"
                  name="name"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formEmpresa.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
