import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { DropdownFilter  } from '../../../../../_metronic/partials/content/dropdown/DropdownFilter'
import { CreateModal } from '../_modals/CreateModal'
import { ListGrid } from './ListGrid'
import { RemoveModal } from '../_modals/RemoveModal'
import { GenericoModel } from '../models/GenericoModel'
import { useGetAll } from "../hooks/genericoHook";     

type Props = {
    code_catalogo: string
}

const View: React.FC<Props> = ({ code_catalogo }) => {

    const [loadingGrid, setLoadingGrid] = useState(true)

    const [ codeCatalog, setCodeCatalog ] = useState(code_catalogo);
    const [ visivilityRemoveModal, setVisivilityRemoveModal ] = useState(false);
    const [ visivilityModal, setVisivilityModal ] = useState(false);
    const [ titleModal, setTitleModal ] = useState('');
    const [ register, setRegister ] = useState<GenericoModel>({
        id: 0,
        code: "",
        name: "",
        order: 0
    });

    // GRID
    const [ reloadGrid, setReloadGrid] = useState(Math.random() * 40);
    const [ filterName, setFilterName ] = useState('');
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const { catalog, count, loadingRequest } = useGetAll( codeCatalog, reloadGrid,  pageSize, currentPage, filterName);
    const totalPages = (Math.ceil( count / pageSize)) ? Math.ceil( count / pageSize) : 1;

    useEffect(() => {
        if( code_catalogo ){
            setLoadingGrid(true)
            setCodeCatalog(code_catalogo);
            setCurrentPage(1)
            setFilterName('')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code_catalogo])

    const addRegister = ( ) => {
        setTitleModal('Agregar registro');
        setVisivilityModal(!visivilityModal);
    }
    const removeRegister = ( row:any ) => {
        setRegister(row);
        setVisivilityRemoveModal(!visivilityRemoveModal);
    }
    const handleFilter = ( name:string ) => {
        setLoadingGrid(true)
        if( name ){
            setFilterName(name) 
        }else{
            setFilterName('');
        }
        setReloadGrid(Math.random() * 40)

    }
    const handleFilterClean = ( ) => {
        setLoadingGrid(true)
        setFilterName('');
        setReloadGrid(Math.random() * 40)
    }
    useEffect(() => {
        if( loadingRequest > 0 ){
            setLoadingGrid(false)
        }
    }, [loadingRequest])

    
    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xl-12'>
                    <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
                        <div className='card-header border-0 pt-5'>
                            <div className='card-title align-items-start flex-column'>
                            <a
                            href='#/'
                            // className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                            className={ (filterName) ? 'btn btn-sm btn-primary': 'btn btn-sm btn-light-primary'}
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                            >
                            <KTSVG
                                path='/media/icons/duotune/general/gen031.svg'
                                className='svg-icon-5 svg-icon-gray-500 me-1'
                            />
                            Filtrar
                            </a>
                            <DropdownFilter handleFilter={ handleFilter } valueFilter={ filterName } handleFilterClean={  handleFilterClean }/>
                            </div>
                            
                            <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Nuevo registro'
                            >
                            <a
                                href='#/'
                                className='btn btn-sm btn-light-primary'
                                id='kt_toolbar_primary_button'
                                onClick={ () => {
                                    addRegister( )
                                } }
                            >
                                <KTSVG
                                path='/media/icons/duotune/arrows/arr075.svg'
                                className='svg-icon-5 svg-icon-gray-500 me-1'
                            />
                                {/* <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' /> */}
                                Nuevo
                            </a>
                            </div>
                            
                        </div>
                        <ListGrid data={ catalog } loadingGrid={ loadingGrid } count={ count } currentPage={ currentPage } 
                        totalPages={ totalPages } valueFilter={ filterName } 
                        onRemoveRegister={ removeRegister } 
                        onPageChange={( pageNumber: number) => {
                            setLoadingGrid(true)
                            setCurrentPage(pageNumber)
                        }} />
                    </div>

                </div>
            </div>
            <CreateModal title={ titleModal } show={ visivilityModal } code_catalogo={ codeCatalog } handleClose={ ( updateGrid:boolean ) => {
                if( updateGrid ){
                    setLoadingGrid(true)
                    setReloadGrid(Math.random() * 40);
                }
                setVisivilityModal(!visivilityModal);
            } }></CreateModal>
            <RemoveModal show={ visivilityRemoveModal } register={ register } handleClose={ ( updateGrid:boolean ) => {
                if( updateGrid ){
                    setLoadingGrid(true)
                    setReloadGrid(Math.random() * 40);
                    const newPage = Math.ceil( ((count - 1 <= 0 ) ? 1 : count - 1) / pageSize);
                    setCurrentPage(newPage)
                }
                setVisivilityRemoveModal(!visivilityRemoveModal);
            } }></RemoveModal>
        </>
    )
}

export { View }
