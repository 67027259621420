import React, { useState } from 'react';
import { KTSVG, SimpleAlert } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GenericoModel } from '../models/GenericoModel';
import { addValueCatalog } from '../../../../service/catalog/Catalogs';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  code_catalogo: string;
};
const genericSchema = Yup.object().shape({
  code: Yup.string().required('Código es requerido.'),
  name: Yup.string().required('Nombre es requerido.'),
  order: Yup.number()
    .min(1, 'Debe ingresar un orden válido.')
    .required('Orden es requerido.'),
});
const CreateModal: React.FC<Props> = ({
  show,
  handleClose,
  title,
  code_catalogo,
}) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [genericInitValues, setGenericInitValues] = useState<GenericoModel>({
    id: 0,
    code: '',
    name: '',
    order: 0,
  });

  const formGeneric = useFormik({
    initialValues: genericInitValues,
    validationSchema: genericSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        addValueCatalog(code_catalogo, values)
          .then((res) => {
            formGeneric.resetForm();
            const {
              data: { message },
            } = res;
            SimpleAlert(message, 3000, 'success');
            setLoading(false);
            handleClose(true);
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            if (details.length) {
              setStatus(`${details[0]?.message}`);
            }
            setLoading(false);
            setSubmitting(false);
            SimpleAlert(resMessageToast, 3000, 'error');
          });
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        formGeneric.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              // formik.resetForm()
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formGeneric.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              {formGeneric.status && (
                <div className="alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formGeneric.status}
                  </div>
                </div>
              )}
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Código</label>
                  {formGeneric.touched.code && formGeneric.errors.code && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formGeneric.errors.code}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Código"
                  {...formGeneric.getFieldProps('code')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="code"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Nombre</label>
                  {formGeneric.touched.name && formGeneric.errors.name && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formGeneric.errors.name}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Nombre"
                  {...formGeneric.getFieldProps('name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="name"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Orden</label>
                  {formGeneric.touched.order && formGeneric.errors.order && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formGeneric.errors.order}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Orden"
                  {...formGeneric.getFieldProps('order')}
                  className="form-control mb-3 mb-lg-0"
                  type="number"
                  min={0}
                  name="order"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formGeneric.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
