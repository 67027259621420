import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getAllTest(
    startIndex: number,
    endIndex: number,
    filters: any
  ) {
    let search: string = '';
    if(filters){
      if(filters.nombre !== ''){
        search += `&nombreOpLike=%${filters.nombre}%`;
      }
    }
    const response = await axios.get(
      `${API_URL}catalogos/test/factory?limit=${startIndex}&page=${endIndex}${search}`
    );
    const data = response.data.doc.data;
    return data;
}

export async function addTest( body:any ) {

  const { tipo, monto, referencia} = body;
  const data = {
    tipo: tipo,
    monto: monto,
    referencia: referencia,
    fecha: "2023-05-18",
    company_id: 1,
  }
  const response = await axios.post(`${API_URL}catalogos/test/factory`, data);
  return response;

}
export async function updateTest( body:any ) {

  const { id, clave, nombre, razon_social, nombre_contacto, correo } = body;
  const response = await axios.put(`${API_URL}catalogos/test/factory/${id}`, {
    clave, 
    nombre,
    razon_social,
    nombre_contacto,
    correo
  });
  return response;

}
export async function getOneTest( id_row:number ) {

  const response = await axios.get(`${API_URL}catalogos/test/factory/${id_row}`);
  const data = response.data.doc.data;
  return data;

}
export async function removeTest( id:number ) {

  const response = await axios.delete(`${API_URL}catalogos/test/factory/${id}`);
  return response;

}