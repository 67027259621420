/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { MonitoringPage } from './components/View';

const MonitoringWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Monitoreo</PageTitle>
      <MonitoringPage />
    </>
  );
};

export { MonitoringWrapper };
