/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ForgotPassword } from './components/ForgotPassword';
import { ConfirmPassword } from './components/ConfirmPassword';
import { Login } from './components/Login';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
// import { Seo } from '../../../SEO/Seo';

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <>
      {/* <Seo
      title='Learning React Helmet!'
      description='Beginner friendly page for learning React Helmet.'
      name='Company name.'
      type='article' /> */}
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            '/media/illustrations/sketchy-1/14.png'
          )})`,
        }}
      >
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {/* begin::Logo */}
          <a href="#" className="mb-12">
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/logo-softnet.png')}
              className="h-150px"
            />
          </a>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Route
                path="/auth/confirm-password/:token"
                component={ConfirmPassword}
              />
              <Route path="/auth/forgot-password" component={ForgotPassword} />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        <div className="d-flex flex-center flex-column-auto p-10"></div>
        {/* end::Footer */}
      </div>
    </>
  );
}
